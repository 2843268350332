import './bootstrap';
import '../css/app.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css'


import React from 'react';
import {createRoot} from 'react-dom/client';
import {createInertiaApp} from '@inertiajs/react';
import {RouteContext} from '@/Hooks/useRoute';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import * as Sentry from "@sentry/react";
import {Inertia} from "@inertiajs/inertia";

const appName =
  window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

if (window.env === "production") {
    Sentry.init({
        dsn: "https://04ecdaf1c792416f814c7123668ce99b@o4504593249337344.ingest.sentry.io/4505283093266432",
        integrations: [
            // new Sentry.BrowserTracing({
            //     // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            //     tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
            // }),
            // new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: 0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

}

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => {
        return resolvePageComponent(
            `./Pages/${name}.tsx`,
            import.meta.glob("./Pages/**/*.tsx"),
        )
    },
    setup({ el, App, props }) {

        Inertia.on('start', (event) => {
            console.log(`Starting a visit to ${event.detail.visit.url}`)
        })

        Inertia.on('before', (event) => {
            console.log(`About to make a visit to ${event.detail.visit.url}`)
        })

        Inertia.on('success', (event) => {
            console.log(`Successfully made a visit to ${event.detail.page.url}`)
        })

        const root = createRoot(el);
        return root.render(
            <RouteContext.Provider value={(window as any).route}>
                {/*<IntercomProvider autoBoot={true} appId={'vt2qri1t'}>*/}
                    <App {...props} />
                {/*</IntercomProvider>*/}
            </RouteContext.Provider>,
        );
    },
});
